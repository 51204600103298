<template>
<div>
	<div class="cover cover--transparent cover--small" :style="'background-image: url(' + require('@/assets/cover-contact.jpg') + ')'">
		<h1 class="cover__title">Contact</h1>
	</div>
	<div class="white-box wrapper wrapper--spaced wrapper--centered">
		<template v-if="isSent">
			<h1 class="title title--spaced">Message Successfully Sent</h1>
			<p class="text text--bold">Someone from our team will get back to you shortly.<br>Thank you for your interest in bringing common sense back to British Columbia!</p>
		</template>
		<template v-else>
			<p class="text">Would you like to learn more about our campaign? Reach out by completing the form below!</p>
			<form class="form" method="post" action="https://commonsensebc.com/webservices/forms.php">
				<div class="form__item form__item--duo"><label>First Name</label><input name="firstname" type="text" autocomplete="given-name" required/></div>
				<div class="form__item form__item--duo"><label>Last Name</label><input name="lastname" type="text" autocomplete="family-name" required/></div>
				<div class="form__item"><label>Email</label><input name="email" type="email" autocomplete="email" required/></div>
				<div class="form__item"><label>Message</label><textarea name="message" rows="10"></textarea></div>
				<input type="hidden" name="source" value="commonsensebc_contact"/>
				<input type="submit" value="Submit" class="button button--yellow" style="margin:auto; margin-top:40px; display:block;"/>
			</form>
		</template>
	</div>
</div>
</template>

<script>
export default {
	computed: {
		isSent () {
			return this.$router.currentRoute.query.hasOwnProperty('sent');
		}
	}
}
</script>

<style scoped>


</style>
